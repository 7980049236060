import React from "react";
import Header from "../components/header.js";
import Footer from "../components/footer.js";
import Content from "../components/index-content.js";

export default () => (
    <div>
        <Header/>
        <Content/>
        <Footer />
    </div>
)