import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import raywon from "../images/raywon2.jpeg";
import "./index-content.css";
import SEO from "./seo.js";

export default () => (
    <div className="index-content">

        <SEO
            title="Raywon Kari"
            description="Raywon's personal website."
            pathname="/"
        />

        <Container>
            <Row>

                <Col sm={6} className="index-content-matter">
                    <h1>Hey, I am Raywon.</h1>
                    <h5>Cloud / Software / DevOps</h5>
                    <p>Indian, living in Sweden.</p>
                </Col>
                <Col sm={6}>
                    <br/>                    
                    <Image src={raywon} alt="raywon" fluid/>
                </Col>

            </Row>
        </Container>

    </div>
)